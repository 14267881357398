"use client";

import React, { useState } from "react";
import i18n from "@/app/i18n/client";
import AppBar from "./appbar";
import HomeCategoriesTabs from "./home-categories-tabs";
import Sidebar from "./sidebar";
import AppFooter from "./app-footer";
import RTL from "@/utils/rtl";
import { DirectionProvider } from "@/context/direction";
import { I18nextProvider } from "react-i18next";
import { usePathname } from "next/navigation";
import { Box, Container } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "@/context/auth";
import CartDrawer from "@/sections/cart/cart-drawer";
import { CartDrawerProvider } from "@/context/cart";
import { CompanyProvider } from "@/context/company";
import { Company } from "@/type/company";

export default function AppLayout({
  children,
  lang,
  company,
}: {
  children: React.ReactNode;
  lang: string;
  company: Company;
}) {
  const pathname = usePathname();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const isLoginPage = pathname === "/login" || pathname === "/signup";

  return (
    <>
      <AuthProvider>
        <DirectionProvider>
          <CompanyProvider company={company} currentLang={lang}>
            <I18nextProvider i18n={i18n}>
              <RTL lang={lang}>
                <CartDrawerProvider>
                  <Toaster position="top-center" />
                  <Container>
                    <Box>
                      <Sidebar
                        company={company}
                        open={open}
                        toggleDrawer={toggleDrawer}
                      />

                      <AppBar
                        handleDrawerOpen={handleDrawerOpen}
                        company={company}
                        lang={lang}
                        showSearch={!isLoginPage}
                      />

                      <CartDrawer lang={lang} />

                      {!isLoginPage ? (
                        <HomeCategoriesTabs company={company} lang={lang} />
                      ) : null}

                      <Box sx={{ minHeight: "100vh" }}>
                        <Box>{children}</Box>
                      </Box>
                    </Box>
                  </Container>
                  <AppFooter company={company} lang={lang} />
                </CartDrawerProvider>
              </RTL>
            </I18nextProvider>
          </CompanyProvider>
        </DirectionProvider>
      </AuthProvider>
    </>
  );
}
