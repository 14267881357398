/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import Image from "next/legacy/image";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { CartItemsProps } from "@/type/cart";
import { getQuantity, getQuantityFromInput } from "@/utils/quantityUtils";
import { useCartDrawer } from "@/context/cart";
import { useCompany } from "@/context/company";
import { useTranslation } from "@/app/i18n/client";

type Props = {
  item: CartItemsProps;
};

export default function CartDrawerCard({ item }: Props) {
  const { company, currentLang } = useCompany();

  const { t } = useTranslation(currentLang, "product", {});

  const { id, quantity, product } = item;

  const isArabic = currentLang === "ar";

  const [cartProduct, setCartProduct] = useState(product);
  const { handleItemQuantityChange, deleteCartItem, setDefaultQuantityOnBlur } =
    useCartDrawer();

  useEffect(() => {
    if (product) {
      const newProducts = {
        ...product,
        name: isArabic ? product.arName : product.enName,
      };

      setCartProduct(newProducts);
    }
  }, [isArabic, product]);

  const { name, regularPrice, isOnSale, onSalePrice, size, color, images } =
    cartProduct;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        gap: "24px",
        alignItems: "center",
        py: "16px",
        px: "8px",
        border: "1px solid rgba(250, 250, 250, 1)",
        ml: "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { lg: "center" },
          gap: { xs: "12px", lg: "24px" },
        }}
      >
        <Box
          sx={{
            minWidth: { xs: "56px", lg: "70px" },
            minHeight: { xs: "44px", lg: "100px" },
            position: "relative",
          }}
        >
          <Image src={images?.[0]} alt="" objectFit="contain" layout="fill" />
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
            }}
          >
            <Typography
              className="block-text text-1"
              sx={{
                color: "secondary.main",
                fontSize: { xs: "12px", lg: "14px" },
                mb: "8px",
              }}
            >
              {name}
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "700",
                  color: "secondary.main",
                }}
              >
                {`${isOnSale ? onSalePrice : regularPrice} ${t(
                  company?.currency || "USD"
                )}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "secondary.main",
                  textDecoration: "line-through",
                  opacity: 0.4,
                  display: isOnSale ? "block" : "none",
                }}
              >
                {`${regularPrice} ${t(company?.currency || "USD")}`}
              </Typography>
            </Box>
          </Box>

          {size && (
            <Typography
              sx={{
                color: "#959595",
                fontSize: { xs: "12px", lg: "14px" },
                mb: "8px",
              }}
            >
              {`${t("size")}:  ${size} `}
            </Typography>
          )}

          {color && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                mb: "8px",
              }}
            >
              <Typography
                sx={{
                  color: "#959595",
                  fontSize: { xs: "12px", lg: "14px" },
                  mb: "8px",
                }}
              >
                {t("color")}
              </Typography>

              <Box
                sx={{
                  border: "1px solid #F5F5F5",
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                  backgroundColor: color,
                  cursor: "pointer",
                  "&:hover": {
                    border: "1px solid",
                    borderColor: "primary.main",
                  },
                }}
              />
            </Box>
          )}

          <Typography
            sx={{
              color: "#959595",
              fontSize: { xs: "12px", lg: "14px" },
              mb: "8px",
            }}
          >
            {`${t("total")} ${(
              (isOnSale ? onSalePrice : regularPrice) * quantity
            ).toLocaleString()} ${t(company?.currency || "USD")} `}
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Button
                sx={{
                  minWidth: "28px",
                  height: "28px",
                  textAlign: "center",
                  border: "1px solid #D9D9D9",
                  backgroundColor: "secondaryBackground",
                  borderRadius: "0px",
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "primary.main",
                    backgroundColor: "secondaryBackground",
                    color: "primary.main",
                  },
                }}
                onClick={() =>
                  handleItemQuantityChange(
                    id,
                    getQuantity("increment", quantity)
                  )
                }
              >
                <AddOutlinedIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: "#656565",
                    "&:hover": { color: "primary.main" },
                  }}
                />
              </Button>

              <TextField
                value={quantity || ""}
                onChange={(e) =>
                  handleItemQuantityChange(
                    item.id,
                    getQuantityFromInput(e.target.value) as any
                  )
                }
                onBlur={() => setDefaultQuantityOnBlur(item.id)}
                type="number"
                InputProps={{
                  sx: {
                    "& input": {
                      textAlign: "center",
                    },
                  },
                }}
                sx={{
                  width: "50px",
                  backgroundColor: "secondaryBackground",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "0px",
                  "& .MuiInputBase-root": {
                    height: "28px",
                    px: "0px",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
              />

              <Button
                sx={{
                  minWidth: "28px",
                  height: "28px",
                  textAlign: "center",
                  border: "1px solid #D9D9D9",
                  backgroundColor: "secondaryBackground",
                  borderRadius: "0px",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  cursor: "pointer",
                  "&:hover": {
                    borderColor: "primary.main",
                    backgroundColor: "secondaryBackground",
                    color: "primary.main",
                  },
                }}
                onClick={() =>
                  handleItemQuantityChange(
                    item.id,
                    getQuantity("decrement", quantity)
                  )
                }
              >
                <RemoveOutlinedIcon
                  sx={{
                    width: "15px",
                    height: "10px",
                    color: "#656565",
                    "&:hover": { color: "primary.main" },
                  }}
                />
              </Button>
            </Box>

            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => deleteCartItem({ cartItemId: id })}
            >
              <Image src={"/images/delete.svg"} alt="" width={12} height={14} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
