"use client";

import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { MouseEvent, useState } from "react";
import { useTranslation } from "@/app/i18n/client";
import { useCookies } from "react-cookie";
import { Company } from "@/type/company";

export default function LangSwitcher({
  lang,
  company,
}: {
  lang: string;
  company: Company;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = Boolean(anchorEl);
  const [, setCookie] = useCookies(["lang"]);

  const { t } = useTranslation(lang, "translation", {});

  const allLangs = [
    {
      label: t("english"),
      value: "en",
    },
    {
      label: t("arabic"),
      value: "ar",
    },
  ];
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = (newLang: string) => {
    setCookie("lang", newLang, { path: "/" });
    //refresh the page
    window.location.reload();
  };

  if (company?.settings?.hideLangSwitcher) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "20px",
      }}
    >
      <Button
        id="basic-button"
        aria-controls={isOpen ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        onClick={handleClick}
        sx={{ minWidth: "min-content", padding: "0px" }}
      >
        {/* <GTranslateIcon sx={{ color: "#000" }} /> */}
        {lang}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {allLangs.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === lang}
            onClick={() => handleChangeLang(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
