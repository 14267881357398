"use client";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { COLORS } from "../constants";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { Almarai } from "next/font/google";
import { useEffect, useState } from "react";
import LoadingSpinner from "@/components/loading-spinner";
import { useCompany } from "@/context/company";
import { Company } from "@/type/company";

const almarai = Almarai({
  subsets: ["arabic"],
  weight: ["300", "400", "700", "800"],
});

const cacheRtl = createCache({
  key: "rtl",
  stylisPlugins: [rtlPlugin],
});

const cacheLtr = createCache({
  key: "ltr",
});

const theme = (company: Company | null, lang: string) =>
  createTheme({
    direction: lang === "en" ? "ltr" : "rtl",
    typography: {
      fontFamily: almarai.style.fontFamily,
    },
    palette: {
      primary: {
        main: company?.settings?.colors?.primaryColor || COLORS.primary, //red
      },
      secondary: {
        main: company?.settings?.colors?.secondaryColor || COLORS.secondary, // black
      },
      third: COLORS.third,
      rateColor: COLORS.rateColor, //orange
      green: COLORS.green,
      white: COLORS.white,
      gray: COLORS.gray,
      primaryBackground: COLORS.background, // white
      secondaryBackground: COLORS.secondaryBackground, // Grey
    },
  });

export default function RTL({
  children,
  lang,
}: {
  children: React.ReactNode;
  lang: string;
}) {
  const isRTL = lang === "ar";
  const { company } = useCompany();

  // This is a workaround to prevent the flash of unstyled content (FOUC)
  // because emotion needs to initialize the cache before rendering the styles
  const [isCacheInitialized, setIsCacheInitialized] = useState(false);

  useEffect(() => {
    setIsCacheInitialized(true);
  }, []);

  if (!isCacheInitialized) {
    return <LoadingSpinner />;
  }

  return (
    <CacheProvider value={isRTL ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={theme(company, lang)}>{children}</ThemeProvider>
    </CacheProvider>
  );
}
