"use client";
import { Company } from "@/type/company";
import { Box } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Image from "next/legacy/image";

export default function AppBar({
  lang,
  company,
}: {
  lang: string;
  company: Company;
}) {
  console.log(company);
  return (
    <Box
      sx={{
        px: "15px",
        width: "100%",
        height: { xs: "62px", height: "91px" },
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
      }}
    >
      <Box>
        <MenuOutlinedIcon sx={{ color: "#222222" }} />
      </Box>

      {/* <Box>
        <Image src={company.logo} alt="logo" width={190} height={50} objectFit="contain" />
      </Box> */}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <SearchIcon
          sx={{ width: "22px", height: "22px", mx: "14px", color: "#222222" }}
        />
        <PersonOutlineSharpIcon
          sx={{
            width: "22px",
            height: "22px",
            display: { xs: "none", md: "block" },
            mx: "14px",
            color: "#222222",
          }}
        />
        <FavoriteBorderIcon
          sx={{
            width: "22px",
            height: "22px",
            display: { xs: "none", md: "block" },
            mx: "14px",
            color: "#222222",
          }}
        />
        <LocalMallOutlinedIcon
          sx={{ width: "22px", height: "22px", mx: "14px", color: "#222222" }}
        />
      </Box>
    </Box>
  );
}
