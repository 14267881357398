"use client";
import React from "react";

import RTL from "@/utils/rtl";
import { Box, Container } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { CartDrawerProvider } from "@/context/cart";
import CartDrawer from "@/sections/cart/cart-drawer";
import { Company } from "@/type/company";
import { CompanyProvider } from "@/context/company";
import { SidebarDrawerProvider } from "@/context/sidebar";
import AppBar from "./appbar";


export default function CustomAppLayout({
  children,
  lang,
  company,
}: {
  children: React.ReactNode;
  lang: string;
  company: Company;
}) {
  return (
    <>
      <CompanyProvider company={company} currentLang={lang}>
        <RTL lang={lang}>
          <Toaster position="top-center" />
          <Box sx={{ overflowX: { xs: "hidden", lg: "unset" } }}>
            <SidebarDrawerProvider>
              <CartDrawerProvider>
                <AppBar lang={lang} company={company} />
                <CartDrawer lang={lang} />
                <Box sx={{ minHeight: "100vh" }}>
                  <Box>{children}</Box>
                </Box>
              </CartDrawerProvider>
            </SidebarDrawerProvider>
          </Box>
        </RTL>
      </CompanyProvider>
    </>
  );
}
