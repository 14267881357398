"use client";

import * as React from "react";
import Image from "next/legacy/image";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";

import { Typography } from "@mui/material";
import { useTranslation } from "@/app/i18n/client";
import { Company } from "@/type/company";
import { useSidebarDrawer } from "@/context/sidebar";
import { useGetCategories } from "@/api/category";
import Link from "next/link";
import { CategoriesProps } from "@/type/category";

const appTabs = [
  "discounts",
  "products",
  "new-arrivals",
  "best-seller",
  "shipping-policy",
];

type SidebarProps = {
  lang: string;
  company: Company;
};

type CategoryDataProps = NavigationLink & Partial<CategoriesProps>;

export default function Sidebar({ lang, company }: SidebarProps) {
  const { t } = useTranslation(lang, "translation", {});

  const { open, toggleDrawer } = useSidebarDrawer();

  const { categories } = useGetCategories(company?.companyId!);

  const getCategoryUrl = (category: CategoryDataProps) => {
    return category.url
      ? category.url
      : category.slug
      ? `/products?categorySlug=${category.slug}`
      : `/products?categoryId=${category.id}`;
  };

  const DrawerList = (
    <Box
      sx={{ width: 260, pl: "24px", pt: "24px" }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
    >
      <Link href="/" style={{ textDecoration: "none" }}>
        {company?.logo ? (
          <Image src={company?.logo} alt="" width={48} height={48} />
        ) : (
          <FlagCircleIcon />
        )}
      </Link>

      <Box sx={{ mb: "32px", mt: "24px" }}>
        <Typography
          sx={{ mb: "16px", color: "rgba(0, 0, 0, 0.4)", fontSize: "14px" }}
        >
          {t("categories")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            color: "secondary.main",
          }}
        >
          {categories.map((category) => (
            <Link
              href={getCategoryUrl(category as CategoryDataProps)}
              legacyBehavior={true}
              key={category.id}
            >
              <Box
                key={category.id}
                sx={{ cursor: "pointer", alignSelf: "flex-start" }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: "9px" }}>
                  <Image
                    src={category.image}
                    alt=""
                    width={16}
                    height={16}
                    objectFit="cover"
                  />
                  <Typography sx={{ fontSize: "16px" }}>
                    {category.enName}
                  </Typography>
                </Box>
              </Box>
            </Link>
          ))}
          {/* {appTabs.map((tab) => (
            <Box key={tab}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "300" }}
                onClick={() => router.push("/")}
              >
                {t(tab)}
              </Typography>
            </Box>
          ))} */}
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={open}
        onClose={() => toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            right: "unset",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
